import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import SpeakerCard from './speakerCard'
import Arrow from '../../static/programm/arrowBlueRight.svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block'
    },
    cardContainer:{
      display: 'flex',
      width: '100vw',
      boxSizing: 'border-box',
      '@media (max-width:1024px)': {
          overflow: 'scroll',
          padding: '7vw',
          margin: '-7vw',
          marginRight: '0px',
          paddingRight: 'calc(7vw - 25px)',
          MsOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
              display: 'none',
          },
          '@media (max-width:767px)': {
            paddingBottom: '70px',
            paddingLeft: '5vw',
            marginBottom: '-70px',
            marginLeft: '-5vw',
        },
  },
    },
    cardWrapper: {
        marginRight: '1.3vw',
        '@media (max-width:1024px)': {
          marginRight: '25px',
        },

    },
    arrow: {
      width: 70,
      padding: 0,
      border: 'none',
      background: 'none',
      marginLeft: 30,
      marginTop: 30,
      cursor: 'pointer',
  },
    rotate: {
      transform: 'rotate(180deg)',
    },
    nav: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (max-width:1024px)': {
          display: 'none',
    },
  },
}))

export default function AllSpeakers(props) {

    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query AllSpeakersQuery{
          allPrismicSpeaker(sort: {fields: data___number_speaker}) {
            edges {
              node {
                data {
                  body {
                    ... on PrismicSpeakerBodyProgramTag {
                      id
                      items {
                        program_tag {
                          document {
                            ... on PrismicProgram {
                              id
                              uid
                              data {
                                type
                              }
                            }
                          }
                        }
                      }
                      slice_type
                    }
                  }
                  speaker_info {
                    text
                  }
                  speaker_name {
                    text
                  }
                  speaker_photo {
                    localFile {
                      childImageSharp {
                        fluid {
                          src
                          srcSet
                        }
                      }
                    }
                    alt
                  }
                  number_speaker
                }
                uid
              }
            }
          }
        }
    
  `);
  const width = 24.3
  const margin = 1.3

  const all_width = data.allPrismicSpeaker.edges.length * -(width + margin) - (2 * -(width + margin))

  const [arrow, setArrow] = React.useState(0)

  const clickLeftArrow = () => {
      var value = arrow + width + margin

      if (value > 0) {
          value = arrow
      } else {
          value = arrow + width + margin
      }

      setArrow(value)
  }

  const clickRightArrow = () => {
      var value = arrow - width - margin

      if (value > all_width) {
          value = arrow - width - margin
      } else {
          value = 0
      }

      setArrow(value)
  }
    

  return (
    <div> 
      <div className={classes.root}>
        <div  className={classes.cardContainer}>
            {data.allPrismicSpeaker.edges.map((content, index) => (
                <div className={classes.cardWrapper}>
                    <SpeakerCard
                    inner={content}
                    index={index}
                    key={"speaker" + index}
                    arrow={arrow}
                    />
                </div>
            ))}
        </div>
        <nav className={classes.nav} >
                  <button aria-label="Arrow Left" onClick={() => clickLeftArrow()} onKeyPress={() => clickLeftArrow()} className={`${classes.arrow} ${classes.rotate}`}> <Arrow  />  </button> 
                  <button aria-label="Arrow Right" onClick={() => clickRightArrow()} onKeyPress={() => clickLeftArrow()} className={classes.arrow}> <Arrow  /> </button> 
        </nav>
      </div>
    </div>
  )
}
