import React from 'react'
import AllSpeakers from './index'
import Heading from './heading'

export default function Programmes() {
  return (
    <div>
      <Heading/>
      <AllSpeakers/>
    </div>
  )
}
