import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {Link} from 'react-scroll'
import Time from '../block/time.js'
import Place from '../block/place.js'
import Logo from '../../static/logo.svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    "@media(max-width: 767px)": {
      display: "block",
     },
  },
  columnContainer:{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title:{
    padding: "6px 15px",
    borderRadius: 13,
    fontSize: "24px",
    marginBottom: '1.46vw',
    fontWeight: 500,
    background:'#E7F0F9',
    "@media(max-width: 1024px)": {
      padding: "6px 15px",
      borderRadius: 10,
      fontSize: "16px",
      marginBottom: '15px',
    },
    "@media(max-width: 767px)": {
      fontSize: "20px",
      marginBottom:'25px',
      },
  },
  titleGreen:{
    padding: "6px 15px",
    borderRadius: 10,
    fontSize: "24px",
    marginBottom: '1.46vw',
    fontWeight: 500,
    marginTop: 0,
    background:'#E1FFED',
    "@media(max-width: 1024px)": {
      padding: "6px 15px",
      fontSize: "16px",
      marginBottom: '15px',
    },
    "@media(max-width: 767px)": {
      marginTop: '20%',
      fontSize: "20px",
      marginBottom:'25px',
      },
  },
  menuItem: {
    transition: 'all 0.5s ease',
    textTransform: 'none',
    cursor: 'pointer',
    marginBottom:'15px',
    "&:last-child": {
      marginBottom:'0px'
    },
    "@media(max-width: 1024px)": {
      marginBottom:'15px',
      "&:last-child": {
        marginBottom:'0px'
      },
    },
    "@media(max-width: 767px)": {
      marginBottom:'25px',
      "&:last-child": {
        marginBottom:'0px'
      },
      },
  },
  logo:{
   height: '90px',
   marginBottom: '33px',
   marginTop: 0,
   "@media(max-width: 1024px)": {
    marginBottom:'-8vh',
   },
   "@media(max-width: 767px)": {
    marginTop: '20%',
    marginBottom:'-10vh',
    display: 'flex',
    width: 'auto',
    },
  },
  info:{
    marginTop: '0',
    "@media(max-width: 1024px)": {
      marginTop:'40px',
    },
    "@media(max-width: 767px)": {
      marginTop: '13%',
      },
  },
}))

export default function Footer({ menuPoint1, menuPoint2, menuPoint3, menuPoint4, ifProgram, ifPartners, ifSpeakers, time, place, google_maps, email, phoneNumber, phoneNumberUrl }) {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.columnContainer}>
      <Typography  className={classes.title}>
        Меню
      </Typography>
        {ifProgram !== 0 ?
          <Link to="program" smooth={true} duration={1000} className={classes.menuItem}>
            <Typography variant='subtitle2'>
              {menuPoint1}
            </Typography>
          </Link>
        :
          null
        }
        {ifSpeakers !== 0 ?
          <Link to="speakers" smooth={true} duration={1000} className={classes.menuItem}>
            <Typography variant='subtitle2'>
            Спикеры
            </Typography>
          </Link>
        :
          null
        }
        <Link to="participants" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
            {menuPoint2}
          </Typography>
        </Link>
        {ifPartners !== 0 ?
          <Link to="partners" smooth={true} duration={1000} className={classes.menuItem}>
            <Typography variant='subtitle2'>
              Партнеры
            </Typography>
          </Link>
        :
          null
        }
        <Link to="about" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
            {menuPoint3}
          </Typography>
        </Link>
        {/* <Link to="contacts" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
            {menuPoint4}
          </Typography>
        </Link> */}
      </div>
      <div className={classes.columnContainer}>
        <Typography  className={classes.titleGreen}>
          Контакты
        </Typography>
        <a
            href={`tel:${phoneNumberUrl}`}
            key='phoneNumber'
            style={{ textDecoration: "none" }}
            className={classes.menuItem}>
          <Typography variant='subtitle2' >
              {phoneNumber}
          </Typography>
        </a>
        <a
            href={`mailto:${email}`}
            key='phoneNumber'
            style={{ textDecoration: "none" }}
            className={classes.menuItem}>
          <Typography variant='subtitle2' >
              {email}
          </Typography>
        </a>
      </div>
      <div className={classes.columnContainer}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.info}>
          <Place google_maps={google_maps}>
              {place}
          </Place>
          <Time >
              {time}
          </Time>
        </div>
      </div>
    </div>
  )
}
