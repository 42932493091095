import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Time from "../../static/time.svg"
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  time: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    marginBottom: '25px',
    '@media (max-width:767px)': {
      marginTop: '15px',
      marginBottom: '15px',
    },
    "@media(max-width: 1024px)": {
      marginTop: '15px',
      marginBottom: '15px',
    },
  },
  icon: {
    marginRight: "0.8em",
    width: '33px',
    '@media (max-width:767px)': {
      width: '33px',
    },
    "@media(max-width: 1024px)": {
      width: '27px',
    },
  },
  dataTime: {
    borderBottom: '2px solid rgb(1, 13, 44, 0.10)',
    color: "#010D2C",
    "@media(max-width: 1024px)": {
      fontSize: '2.2vw',
    },
    "@media(max-width: 767px)": {
      fontSize: '1em',
    }
  },
}));

export default function TimePlace ({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.time}>
      <div className={classes.icon}>
        <Time />
      </div>
      <Typography variant='body1' className={classes.dataTime}>
        { children }
      </Typography>
    </div>
  )
}
