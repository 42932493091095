import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import RenderTag from './tag/renderTag'

const useStyles = makeStyles(theme => ({
    root: {
        width: '24.3vw',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: '#FFFFFF',
        boxShadow:" 0px 15px 44px rgba(0, 0, 0, 0.1)",
        borderRadius: "33px",
        height: '100%',
        '@media (max-width:1024px)': {
            width: '30vw',
        },
        '@media (max-width:767px)': {
            width: '75vw',
        },
    },

    speackerPhoto:{
        height: '26.04vw',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center top',
        margin: `0 auto`,
        borderRadius: '33px 33px 0px 0px',
        '@media (max-width:1024px)': {
            height: '250px',
        },
        '@media (max-width:767px)': {
            height: '350px',
        },
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "2.60vw 2.34vw",
        '@media (max-width:1024px)': {
            padding: "2.60vw 2.34vw",
        },
        '@media (max-width:767px)': {
            padding: "35px 30px",
        },
    },
    speakerName: {
        paddingBottom: '1.35vw',
        fontSize: "1.56vw",
        color: "#001224",
        fontWeight: 700,
        '@media (max-width:1024px)': {
            paddingBottom: '2vw',
            fontSize: "2vw",
        },
        '@media (max-width:767px)': {
            paddingBottom: '20px',
            fontSize: "4.6vw",

        },
    },
    speakerInfo: {
        paddingBottom: '1.35vw',
        width: '16vw',
        fontSize: "18px",
        fontWeight: 400,
        '@media (max-width:1024px)': {
            paddingBottom: '2vw',
            fontSize: "1.5vw",
            width: '90%',
        },
        '@media (max-width:767px)': {
            width: '90%',
            paddingBottom: '20px',
            fontSize: "14px",
        },
    },
}))

export default function SpeakerCard(props) {

  const classes = useStyles();

  return (
    <div  className={classes.root} style={{
        transform: `translate3d(${props.arrow}vw, 0vw, 0vw)`,
        transition: 'transform 0.4s ease 0s', }}>
            <picture className={classes.speackerPhoto}>
                    <sourse
                        className={classes.speackerPhoto}
                        srcSet={props.inner.node.data.speaker_photo.localFile.childImageSharp.fluid.srcWebp }
                        type="image/webp"/>
                    <img
                        className={classes.speackerPhoto}
                        srcSet={props.inner.node.data.speaker_photo.localFile.childImageSharp.fluid.src}
                        alt={props.inner.node.data.speaker_photo.alt} />
            </picture>
        <div className={classes.textContainer}>
            <Typography className={classes.speakerName}>
                {props.inner.node.data.speaker_name.text}
            </Typography>
            <Typography className={classes.speakerInfo}>
                {props.inner.node.data.speaker_info.text}
            </Typography>
            <div>
                {props.inner.node.data.body.map((inner, i) => (
                    <RenderTag
                    inner={inner}
                    i={i}
                    key={"tag_name" + i}
                    type={inner.slice_type}
                    />
                ))}
            </div>
        </div>
    </div>
  )
}
