import React from "react"
import {makeStyles} from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  placePhoto: {
    position: 'relative',
    zIndex: '5',
    display: 'flex',
    '@media (max-width:767px)': {
      display: 'block',
    },
  },
  imgStart: {
    width: '32%',
    height: 'calc(86vw * 0.32)',
    marginRight: '2%',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:767px)': {
      width: '100%',
      height: '90vw',
      marginRight: '0%',
      marginBottom: '4%',
      position: 'relative',
      left: '-30%',
    },
  },
  imgMiddle: {
    width: '32%',
    height: 'calc(86vw * 0.32)',
    marginRight: '2%',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:767px)': {
      width: '100%',
      height: '90vw',
      marginRight: '0%',
      marginBottom: '4%',
      position: 'relative',
      left: '30%',
    },
  },
  imgEnd: {
    width: '32%',
    height: 'calc(86vw * 0.32)',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:767px)': {
      width: '100%',
      height: '90vw',
      position: 'relative',
      left: '-30%',
    },
  },
  loc: {
    width: '100%',
    height: '100%',
    marginBottom: 0,
    'object-fit': 'cover',
  },
}));

  export default function Photo ({ img_loc_1src, img_loc_1srcWebp, img_loc_1_alt, img_loc_2src, img_loc_2srcWebp, img_loc_2_alt, img_loc_3src, img_loc_3srcWebp, img_loc_3_alt, }) {
  const classes = useStyles();
  return (
  <div>
    <div>
      <div className={classes.placePhoto} >
        <picture className={classes.imgStart}>
          <sourse
            className={classes.loc}
            srcSet={img_loc_1srcWebp}
            type="image/webp"/>
          <img
              className={classes.loc}
              srcSet={img_loc_1src} 
              alt={img_loc_1_alt}/>
        </picture>
        <picture className={classes.imgMiddle}>
          <sourse
            className={classes.loc}
            srcSet={img_loc_2srcWebp}
            type="image/webp"/>
          <img
              className={classes.loc}
              srcSet={img_loc_2src}
              alt={img_loc_2_alt} />
        </picture>
        <picture className={classes.imgEnd}>
          <sourse
            className={classes.loc}
            srcSet={img_loc_3srcWebp}
            type="image/webp"/>
          <img
              className={classes.loc}
              srcSet={img_loc_3src}
              alt={img_loc_3_alt} />
        </picture>
      </div>
    </div>
  </div>
  )
}
