import React from 'react'
import { makeStyles } from '@material-ui/core'
import Point from './point'
import Arrow from '../../static/programm/arrowBlueRight.svg'

const useStyles = makeStyles(theme => ({
    pointsContainer: {
        display: 'flex',
        width: '100vw',
        boxSizing: 'border-box',
        '@media (max-width:1024px)': {
            overflow: 'scroll',
            padding: '7vw',
            margin: '-7vw',
            marginRight: '0px',
            paddingRight: '5.7vw',
            MsOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        '@media (max-width:767px)': {
            marginLeft: '-5vw',
            paddingLeft: '5vw',
            paddingRight: '3vw',
        },
    },
    arrow: {
        width: 70,
        padding: 0,
        border: 'none',
        background: 'none',
        marginLeft: 30,
        marginTop: 30,
        cursor: 'pointer',
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
    nav: {
        width: '100%',
        display: props => props.content.length > 3 ? 'flex' : 'none',
        justifyContent: 'flex-end',
        '@media (max-width:1024px)': {
            display: 'none !important',
        },
    },
}))

export default function AllPoints(props) {

    const width = 24.3
    const margin = 1.3

    const all_width = props.content.length * -(width + margin) - (2 * -(width + margin))

    const [arrow, setArrow] = React.useState(0)

    const clickLeftArrow = () => {
        var value = arrow + width + margin

        if (value > 0) {
            value = arrow
        } else {
            value = arrow + width + margin
        }

        setArrow(value)
    }

    const clickRightArrow = () => {
        var value = arrow - width - margin

        if (value > all_width) {
            value = arrow - width - margin
        } else {
            value = 0
        }

        setArrow(value)
    }

    const classes = useStyles(props)
    return (
        <div className={classes.root}>
            <div className={classes.gradientContainer}>
                <div className={classes.pointsContainer}>
                    {props.content.map((content, index) => (
                        <Point
                            content={content}
                            index={index}
                            key={"block_name_slider" + index}
                            arrow={arrow}
                            width={width}
                            margin={margin}
                        />
                    ))}
                </div>
            </div>
            <nav className={classes.nav} >
               <button aria-label="Arrow Left" onClick={() => clickLeftArrow()} onKeyPress={() => clickLeftArrow()} className={`${classes.arrow} ${classes.rotate}`}> <Arrow  />  </button> 
               <button aria-label="Arrow Right" onClick={() => clickRightArrow()} onKeyPress={() => clickLeftArrow()} className={classes.arrow}> <Arrow  /> </button> 
            </nav>
        </div>
        
    )
}