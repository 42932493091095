import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Logo from "../../static/logo.svg"
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      marginTop: '15%',
    },
    '@media (max-width:767px)': {
      marginTop: '30%',
      display: 'block',
    },
  },
  longLogo: {
    height: 70,
    display: 'flex',
    '& svg': {
      width: 'auto',
    },
    '@media (max-width: 1024px)': {
      height: 80,
    },
  },
  img: {
    height: '100%',
  },
  longLogo1: {
    height: 60,
    marginBottom: 0,
    display: 'block',
    '@media (max-width: 1280px)': {
      height: 55,
    },
    '@media (max-width: 1220px)': {
      height: 50,
    },
    '@media (max-width: 1024px)': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 30,
    },
    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
      height: 60,
    },
  },
  longLogoRos: {
    height: 60,
    marginBottom: 0,
    marginLeft: '10%',
    display: 'block',
    marginTop: 0,
    '@media (max-width: 1280px)': {
      height: 55,
    },
    '@media (max-width: 1220px)': {
      height: 50,
    },
    '@media (max-width: 1024px)': {
      display: 'flex',
      justifyContent: 'flex-end',

    },
    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
      height: 60,
      marginBottom: 30,
      marginLeft: '0%',
    },
  },
  line: {
    marginTop: 40,
    marginBottom: 55,
    height: 1,
    '@media (max-device-width: 1024px)': {
      marginTop: 20,
      marginBottom: 25,
    },
    '@media (max-width:767px)': {
      marginTop: 20,
      marginBottom: 25,
    },
  },
  text: {
    textAlign: 'left',
    fontSize: '1.3vw',
    lineHeight: '1.6em',
    '@media (max-width: 1024px)': {
      fontSize: '1.7vw',
      lineHeight: '1.6em',
    },
    '@media (max-width:767px)': {
      fontSize: '5vw',
      lineHeight: '9vw',
      textAlign: 'left',
    },
  },
  text1: {
    textAlign: 'right',
    fontSize: '1.3vw',
    lineHeight: '1.6em',
    '@media (max-width: 1024px)': {
      fontSize: '1.7vw',
      lineHeight: '1.6em',
    },
    '@media (max-width:767px)': {
      fontSize: '5vw',
      lineHeight: '9vw',
      textAlign: 'left',
    },
  },
  about: {
    width: '45%',
    '@media (max-width:767px)': {
      width: '100%',
      '&:nth-child(2)': {
        marginTop: '20%',
      },
    },
    svg:{
      height:'100%'
    }
  },
  logoContainer: {
    display: 'flex',
    height: 70,
     justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      display: 'block',
      height: 130,
    },
    '@media (max-width: 767px)': {
      display: 'block',
      height: 160,
    },

  }
}))

export default function About ({ textInter, textFound, logoFound_srcWebp, logoFound_src, logoFound_alt, logoRos_srcWebp, logoRos_src, logoRos_alt, content_url, ros_url } ) {
  const classes = useStyles();
  return (
    <div className={classes.root}>

      <div className={classes.about}>
        <div className={classes.longLogo}>
          <Logo className={classes.svg} />
        </div>

        <Divider className={classes.line} />

        <Typography variant='body2' className={classes.text}>
          {textInter}
        </Typography>
      </div>

      <div className={classes.about}>
        <div className={classes.logoContainer}>
          <div className={classes.longLogo1}>
            <a href={content_url} target = "_blank"  rel="noreferrer" className={classes.url}>

              <picture className={classes.pictures}>
                <source
                  className={classes.img}
                  srcSet={logoFound_srcWebp}
                  type="image/webp"/>
                <img
                    className={classes.img}
                    srcSet={logoFound_src} 
                    alt={logoFound_alt}/>
              </picture>
              </a>
          </div>
          <div className={classes.longLogoRos}>
            <a href={ros_url} target = "_blank"  rel="noreferrer" className={classes.url}>
              <picture className={classes.pictures}>
                <source
                  className={classes.img}
                  srcSet={logoRos_srcWebp}
                  type="image/webp"/>
                <img
                    className={classes.img}
                    srcSet={logoRos_src} 
                    alt={logoRos_alt}/>
              </picture>
              </a>
          </div>
        </div>
        
        <Divider className={classes.line} />

        <Typography variant='body2' className={classes.text1}>
          {textFound}
        </Typography>
      </div>

    </div>
  )
}
