import React from 'react'
import AllPoints from './allPoints'
import Heading from './heading'

export default function Programmes({ title_partners, content }) {
  return (
    <div>
      <Heading title_partners={title_partners} />
      <AllPoints content={content} />
    </div>
  )
}
