import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: '10%',
    marginBottom: '3%',
    '@media (max-width: 1024px)': {
      marginTop: '15%',
    },
    '@media (max-width:767px)': {
      marginTop: '30%',
      marginBottom: '10%',
    },
  },
}))

export default function Heading({ title_partners }) {

  const classes = useStyles();

  return (
    <Typography variant='h2' className={classes.heading}>
      {title_partners}
    </Typography>
  )
}
