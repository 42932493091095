import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '@media (max-width:767px)': {
      display: 'block',
    },
  },
  row: {
    width: 'calc(96% / 3)',
    marginRight: '2%',
    '@media (max-width:767px)': {
      width: '100%',
    },
  },
  rowEnd: {
    width: 'calc(96% / 3)',
    '@media (max-width:767px)': {
      width: '100%',
    },
  },
  blue: {
    padding: '23px 30px',
    background: '#0047FF',
    borderRadius: '20px',
    marginBottom: 'calc(86vw * 0.02)',
    '@media (max-width: 1024px)': {
      padding: '16px 20px',
    },
    '@media (max-width:767px)': {
      padding: '16px 20px',
      '&:nth-child(2)': {
        background: '#00DF68',
      },
    },
  },
  green: {
    padding: '23px 30px',
    background: '#00DF68',
    borderRadius: '20px',
    marginBottom: 'calc(86vw * 0.02)',
    '@media (max-width: 1024px)': {
      padding: '16px 20px',
    },
    '@media (max-width:767px)': {
      padding: '16px 20px',
      '&:nth-child(1)': {
        background: '#0047FF',
      },
    },
  },
  participant: {
    color: 'white',
    fontSize: '1.6vw',
    lineHeight: '1.4em',
    '@media (max-device-width: 1024px)': {
      fontSize: '1.6vw',
    },
    '@media (max-width:767px)': {
      fontSize: '4.5vw',
    },
  },
}))

export default function Participants({ participant1, participant2, participant3, participant4, participant5 }) {

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.row}>
        <div className={classes.blue}>
          <Typography variant='h6' className={classes.participant}>
            {participant1}
          </Typography>
        </div>
        <div className={classes.green}>
          <Typography variant='h6' className={classes.participant}>
            {participant2}
          </Typography>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.green}>
          <Typography variant='h6' className={classes.participant}>
            {participant3}
          </Typography>
        </div>
        <div className={classes.blue}>
          <Typography variant='h6' className={classes.participant}>
            {participant4}
          </Typography>
        </div>
      </div>

      <div className={classes.rowEnd}>
        <div className={classes.blue}>
          <Typography variant='h6' className={classes.participant}>
            {participant5}
          </Typography>
        </div>
      </div>

    </div>
  )
}
