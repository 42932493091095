import React from 'react'
import { Link } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  menuItem: {
    background: '#E7F0F9',
    display: 'flex',
    borderRadius: 10,
    padding: '5px 15px',
    marginBottom: '19px',
    transition: 'all 0.5s ease',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      background: '#E1FFED',
    },
  },
  menuM: {
    display: 'flex',
  },
}))

export default function SmoothLinkMobile({ menuPoint1, menuPoint2, menuPoint3, menuPoint4, ifProgram, ifPartners,  menuPoint25, ifSpeakers,  menuPoint15 }) {

  const classes = useStyles();

  return (
    <div>
      {ifProgram !== 0 ?
        <div className={classes.menuM}>
          <Link to="program" smooth={true} duration={1000} className={classes.menuItem}>
            {menuPoint1}
          </Link>
        </div>
      :
        null
      }
      {ifSpeakers !== 0 ?
        <div className={classes.menuM}>
          <Link to="speakers" smooth={true} duration={1000} className={classes.menuItem}>
            {menuPoint15}
          </Link>
        </div>
      :
        null
      }
      <div className={classes.menuM}>
        <Link to="participants" smooth={true} duration={1000} className={classes.menuItem}>
          {menuPoint2}
        </Link>
      </div>
      {ifPartners !== 0 ?
        <div className={classes.menuM}>
          <Link to="partners" smooth={true} duration={1000} className={classes.menuItem}>
            {menuPoint25}
          </Link>
        </div>
      :
        null
      }
      <div className={classes.menuM}>
        <Link to="about" smooth={true} duration={1000} className={classes.menuItem}>
          {menuPoint3}
        </Link>
      </div>
      <div className={classes.menuM}>
        <Link to="contacts" smooth={true} duration={1000} className={classes.menuItem}>
          {menuPoint4}
        </Link>
      </div>
    </div>
  )
}
