import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    tagContainer: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    tag:{
        padding: "11px 17px",
        borderRadius: '13px',
        marginRight: '15px',
        marginBottom:'15px',
        fontWeight: 450,
        fontSize: '16px',
        '&:nth-child(odd)': {
            background:'#E7F0F9',
        },
        '&:nth-child(even)': {
            background:'#E1FFED',
        },
        '@media (max-width:1024px)': {
            fontSize: "1.3vw",
            padding: "6px 15px",
        },
        '@media (max-width:767px)': {
            fontSize: '14px',
            padding: "11px 17px",
        },
    }
}))

export default function Tag(props) {

  const classes = useStyles();

  return (
    <div className={classes.tagContainer}>
        {props.inner.items.map((items) => (
            <div className={classes.tag}>
                {items.program_tag.document.data.type}
            </div>
        ))}
    </div>
  )
}
