import React from 'react'
import Background from '../../static/background/bgMobile2.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  bg: {
    position: 'absolute',
    width: '100vw',
    marginLeft: '-5vw',
    marginTop: '50%',
    zIndex: '1',
    '@media (min-width:768px)': {
      display: 'none',
    },
  },
}))

export default function BackgroundStart() {

  const classes = useStyles();

  return (
    <div className={classes.bg}>
      <Background />
    </div>
  )
}
