import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import LanguageIcon from '../../static/languageIcon.svg'
// import Menu from '@material-ui/core/Menu'
// import ClickAwayListener from '@material-ui/core/ClickAwayListener'
// import Grow from '@material-ui/core/Grow'
// import Paper from '@material-ui/core/Paper'
// import Popper from '@material-ui/core/Popper'
// import MenuItem from '@material-ui/core/MenuItem'
// import MenuList from '@material-ui/core/MenuList'
// import Divider from '@material-ui/core/Divider'
import Modal from '@material-ui/core/Modal'
import Slide from '@material-ui/core/Slide'
// import Logo from '../../static/logo.svg'
import Logo2 from '../../static/logo2.svg'
// import { Link } from "gatsby"
import MenuBurger from '../../static/menuBurger.svg'
import SmoothLinksMobile from '../block/smoothLinkMobile'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  modalM: {
    padding: 0,
    outline: 'none',
  },
  paper: {
    background: 'rgb(255, 255, 255, 1)',
    width: '70%',
    height: '100%',
    padding: '5% 7% 7% 7%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  menuItem: {
    background: '#E7F0F9',
    display: 'flex',
    borderRadius: 10,
    padding: '5px 15px',
    marginBottom: '19px',
    transition: 'all 0.5s ease',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      background: '#E1FFED',
    },
  },
  language: {
    paddingLeft: '15px',
    marginTop: '21px',
    display: 'flex',
    alignItems: 'center',
  },
  languageItem: {
    marginRight: '5px',
  },
  languageIcon: {
    width: '13px',
  },
  modal: {
    borderRadius: 10,
    padding: '5px 12px',
  },
  modalItem: {
    borderRadius: 10,
    padding: '5px 15px',
    paddingRight: '40px',
    transition: 'all 0.5s ease',
    '&:hover': {
      background: '#E1FFED',
    },
  },
  logoM: {
    width: '70%',
    marginBottom: '20%',
    height: '10%',
  },
  burger: {
    width: '33px',
  },
  menuM: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function Header({ menuPoint1, menuPoint2, menuPoint3, menuPoint4, ifProgram, ifPartners, ifSpeakers }) {

  const classes = useStyles();
  const [open] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };
  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };
  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const [open1, setOpen1] = React.useState(false);
  // const [open2, setOpen2] = React.useState(false);


  // end of MenuList js

  return (
    <div>
      <div className={classes.logoDivMobile}>
          <div className={classes.menuBurger}>
            <div className={classes.menuBurgerInside}>
              <MenuBurger className={classes.burger} onClick={() => setOpen1(true)} />
            </div>
          </div>

          <Modal
            aria-labelledby="update-modal-title"
            aria-describedby="update-modal-description"
            className={classes.modalM}
            open={open1}
            disableAutoFocus={true}
            onClose={() => setOpen1(false)}>
            <Slide direction="right" in={open1} mountOnEnter unmountOnExit>

              <div className={classes.paper}>

                <Logo2 onClick={() => setOpen1(false)} className={classes.logoM}/>

                <div className={classes.menuM}>
                  <SmoothLinksMobile
                    ifProgram={ifProgram}
                    ifSpeakers ={ifSpeakers}
                    ifPartners={ifPartners}
                    menuPoint1={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>{menuPoint1}</Typography>}
                    menuPoint15={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>Спикеры</Typography>}
                    menuPoint2={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>{menuPoint2}</Typography>}
                    menuPoint25={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>Партнеры</Typography>}
                    menuPoint3={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>{menuPoint3}</Typography>}
                    menuPoint4={<Typography variant='subtitle2' onClick={() => setOpen1(false)}>{menuPoint4}</Typography>}
                    />
                </div>

                  <div
                    // ref={anchorRef}
                    // aria-controls={open ? 'menu-list-grow' : undefined}
                    // aria-haspopup="true"
                    // onClick={handleToggle}
                    // className={classes.language}
                  >
                    {/* <div className={classes.languageItem}>
                      <Typography variant='subtitle2'>
                        Рус
                      </Typography>
                    </div>
                    <div className={classes.languageIcon}>
                      <LanguageIcon />
                    </div> */}
                  </div>

              </div>
            </Slide>
          </Modal>

        </div>

        {/* start of MenuList (computer) */}

        {/* <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              className={classes.modal}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >

                    <MenuItem onClick={handleClose} className={classes.modalItem}>
                      <Typography variant='subtitle2'>
                        Русский
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} className={classes.modalItem}>
                      <Typography variant='subtitle2'>
                        English
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} className={classes.modalItem}>
                      <Typography variant='subtitle2'>
                        Français
                      </Typography>
                    </MenuItem>

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}

      {/* end of MenuList */}

    </div>
  )
}
