import React from "react"
import Tag from './index'


const RenderBlock = props => {
  const inner = props.inner
  const contentType = props.inner.slice_type


  let contentBlock
  
  switch (contentType) {
    case "program_tag":
      contentBlock = (
            <Tag
              inner={inner}/>
    )
      break
    default:
      return ""
  }
  return <div>{contentBlock}</div>
}
export default RenderBlock