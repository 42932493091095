import React from 'react'
import Participants from './participants'
import Heading from './heading'

export default function Programmes({ title_participants, participant1, participant2, participant3, participant4, participant5 }) {
  return (
    <div>
      <Heading title_participants={title_participants}/>
      <Participants
        participant1={participant1}
        participant2={participant2}
        participant3={participant3}
        participant4={participant4}
        participant5={participant5} />
    </div>
  )
}
