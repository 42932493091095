import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  numbers: {
    display: 'flex',
    marginTop: '10%',
    '@media (max-width: 1024px)': {
      marginTop: '13%',
    },
    '@media (max-width:767px)': {
      display: 'block',
      marginTop: '30%',
    },
  },
  numberBlock:{
    marginRight: '10%',
    '@media (max-width: 1024px)': {
      width: '20%',
    },
    '@media (max-width:767px)': {
      marginBottom: '15%',
      marginRight: 0,
      width: '100%',

    },
  },
  numbersText:{
    fontSize: "5.5em",
    fontWeight: 'bold',
    color: "#010D2C",
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: "8vw",
    },
    '@media (max-width:767px)': {
      fontSize: "5.5em",
    },
  },
  numberTitle: {
    width: '99%',
    color: "#010D2C",
    fontWeight: 'regular',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '35%',
    },
  },

}))

export default function Place ({speakerNumber, speakerTitle, programsNumber, programsTitle, partnersNumber, partnersTitle} ) {
const classes = useStyles();
return (
  <div className={classes.numbers}>
    <div className={classes.numberBlock}>
      <Typography variant='h1' className={classes.numbersText}>
        {speakerNumber}
      </Typography>
      <Typography variant='body2' className={classes.numberTitle}>
        {speakerTitle}
      </Typography>
    </div>
    <div className={classes.numberBlock}>
      <Typography variant='h1' className={classes.numbersText}>
        {programsNumber}
      </Typography>
      <Typography variant='body2' className={classes.numberTitle}>
        {programsTitle}
      </Typography>
    </div>
    <div >
      <Typography variant='h1' className={classes.numbersText}>
        {partnersNumber}
      </Typography>
      <Typography variant='body2' className={classes.numberTitle}>
        {partnersTitle}
      </Typography>
    </div>
  </div>

)
}
