import React from 'react'
import Background from '../../static/background/bg3.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  bg: {
    position: 'relative',
    width: '57vw',
    marginLeft: '36vw',
    zIndex: '1',
    marginTop: '-10%',
    marginBottom: '-32%',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)': {
      display: 'none',
    },
    '@media (max-width:767px)': {
      display: 'none',
    },
  },
}))

export default function BackgroundStart() {

  const classes = useStyles();

  return (
    <div className={classes.bg}>
      <Background />
    </div>
  )
}
