import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: '10%',
    marginBottom: '3%',
    '@media (max-width: 1024px)': {
      marginTop: '15%',
    },
    '@media (max-width:767px)': {
      marginTop: '30%',
      marginBottom: '10%',
    },
  },
}))

export default function HeadingSpeakers() {

  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query HeadingSpeakersQuery{
      allPrismicMainpage {
        edges {
          node {
            data {
              title_speakers {
                text
              }
            }
          }
        }
      }
    }
    
  `);
 

  return (
    <div>
          <Typography variant='h2' className={classes.heading}>
            {data.allPrismicMainpage.edges[0].node.data.title_speakers.text}
          </Typography>
    </div>
  )
}
