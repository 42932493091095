import React from 'react'
import Background from '../../static/background/bg1.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  bg: {
    position: 'absolute',
    width: '45vw',
    right: '-7vw',
    top: '-30vw',
    zIndex: '1',
    '@media (max-width:1024px)': {
      zIndex: '-10',
      right: '-24vw',
      top: '-50vw',
    },
    '@media (max-width:767px)': {
      display: 'none',
    },
  },
}))

export default function BackgroundStart() {

  const classes = useStyles();

  return (
    <div className={classes.bg}>
      <Background />
    </div>
  )
}
