import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import LanguageIcon from '../../static/languageIcon.svg'
// import Menu from '@material-ui/core/Menu'
// import ClickAwayListener from '@material-ui/core/ClickAwayListener'
// import Grow from '@material-ui/core/Grow'
// import Paper from '@material-ui/core/Paper'
// import Popper from '@material-ui/core/Popper'
// import MenuItem from '@material-ui/core/MenuItem'
// import MenuList from '@material-ui/core/MenuList'
import {Link} from 'react-scroll'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    background: '#E7F0F9',
    borderRadius: 10,
    padding: '5px 15px',
    marginRight: '8px',
    transition: 'all 0.5s ease',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#E1FFED',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      borderRadius: 6,
      padding: '4px 10px',
      marginRight: '4px',
    }
  },
  language: {
    marginLeft: '10px',
    display: 'none',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  languageItem: {
    marginRight: '5px',
  },
  languageIcon: {
    width: '13px',
  },
  modal: {
    borderRadius: 10,
    padding: '5px 12px',
  },
  modalItem: {
    borderRadius: 10,
    padding: '5px 15px',
    paddingRight: '40px',
    transition: 'all 0.5s ease',
    '&:hover': {
      background: '#E1FFED',
    },
  },
}))

export default function Header({ menuPoint1, menuPoint2, menuPoint3, menuPoint4, ifProgram, ifPartners, ifSpeakers }) {

  const classes = useStyles();

  // start of MenuList js

  const [open] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // end of MenuList js

  return (
    <div className={classes.root}>
      {ifProgram !== 0 ?
        <Link to="program" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
            {menuPoint1}
          </Typography>
        </Link>
      :
        null
      }
      {ifSpeakers !== 0 ?
        <Link to="speakers" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
           Спикеры
          </Typography>
        </Link>
      :
        null
      }
      <Link to="participants" smooth={true} duration={1000} className={classes.menuItem}>
        <Typography variant='subtitle2'>
          {menuPoint2}
        </Typography>
      </Link>
      {ifPartners !== 0 ?
        <Link to="partners" smooth={true} duration={1000} className={classes.menuItem}>
          <Typography variant='subtitle2'>
            Партнеры
          </Typography>
        </Link>
      :
        null
      }
      <Link to="about" smooth={true} duration={1000} className={classes.menuItem}>
        <Typography variant='subtitle2'>
          {menuPoint3}
        </Typography>
      </Link>
      <Link to="contacts" smooth={true} duration={1000} className={classes.menuItem}>
        <Typography variant='subtitle2'>
          {menuPoint4}
        </Typography>
      </Link>
      {/* <div
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.language}
      >
        <div className={classes.languageItem}>
          <Typography variant='subtitle2'>
            Рус
          </Typography>
        </div>
        <div className={classes.languageIcon}>
          <LanguageIcon />
        </div>
      </div> */}

      {/* start of MenuList (computer) */}

      {/* <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            className={classes.modal}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >

                  <MenuItem onClick={handleClose} className={classes.modalItem}>
                    <Typography variant='subtitle2'>
                      Русский
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.modalItem}>
                    <Typography variant='subtitle2'>
                      English
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.modalItem}>
                    <Typography variant='subtitle2'>
                      Français
                    </Typography>
                  </MenuItem>

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}

      {/* end of MenuList */}

    </div>
  )
}
