import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    main: {
        width: props => props.width + 'vw',
        marginRight: props => props.margin + 'vw',
        padding: props => props.margin * 3 + 'vw',
        borderRadius: 33,
        boxSizing: 'border-box',
        boxShadow: '0px 10px 30px rgba(0,0,0,0.10)',
        flex: 'none',
        '@media (max-width:1024px)': {
            width: '32vw !important',
            padding: '5vw !important',
        },
        '@media (max-width:767px)': {
            width: '60vw !important',
            padding: '9vw !important',
            marginRight: '2vw !important',
        },
    },
    cardImage: {
        width: '100%',
        margin: 0,
    },
}))

export default function Point(props) {

    const classes = useStyles(props)

    return (
        <div className={classes.main} style={{
            transform: `translate3d(${props.arrow}vw, 0px, 0px)`,
            transition: 'transform 0.4s ease 0s', }}>
            <div className={classes.root}>
                <picture>
                    <source
                        className={classes.cardImage}
                        srcSet={props.content.primary.partner_logo.localFile.childImageSharp.fluid.src}
                        type="image/webp"
                    />
                    <img
                        className={classes.cardImage}
                        src={props.content.primary.partner_logo.localFile.childImageSharp.fluid.srcWebp}
                        alt={props.content.primary.partner_logo.localFile.alt}
                    />
                </picture>
            </div>
        </div>
    )
}