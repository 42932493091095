import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Locat from "../../static/location.svg"
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  place: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '114px',
    marginBottom: '50px',
    textDecoration: 'none',
    '@media (max-width: 1024px)': {
      marginTop: '8vh',
    },
    '@media (max-width:767px)': {
      marginTop: '30%',
    },
  },
  icon: {
    marginRight: "0.8em",
    width: '33px',
    '@media (max-width: 1024px)': {
      width: '27px',
    },
    '@media (max-width:767px)': {
      width: '33px',
    },

  },
  dataTime: {
    borderBottom: '2px solid rgb(1, 13, 44, 0.10)',
    color: "#010D2C",
    '@media (max-width: 1024px)': {
      fontSize: '2.2vw',
    },
    '@media (max-width:767px)': {
      fontSize: '1em',
    },
  },
}));

export default function Place ({ children, google_maps }) {
const classes = useStyles();
return (
  <a href={google_maps} target = "_blank" rel="noreferrer" className={classes.place}>
    <div>
      <Locat className={classes.icon} />
    </div>
    <Typography variant='body1' className={classes.dataTime}>
      {children}
    </Typography>
  </a>
)
}
