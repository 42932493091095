import React from "react"
// import { Link } from "gatsby"
import {graphql, useStaticQuery} from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
// import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography'
// import Button from "../components/block/button.js"
import PlacePhoto from "../components/block/placePhoto.js"
import Place from "../components/block/place.js"
import PlaceLocation from "../components/block/placeLocation.js"
import Time from "../components/block/time.js"
import Numbers from "../components/block/numbers.js"
import About from "../components/block/about.js"
import Header from "../components/layout/headerMain.js"
import BackgroundStart from '../components/background/bg1'
import Background2 from '../components/background/bg2'
import BackgroundLocation from '../components/background/bg3'
import BackgroundStartMobile from '../components/background/bgMobile1'
import BackgroundStartMobile2 from '../components/background/bgMobile2'
import Programmes from '../components/programmes'
import Partners from '../components/partners'
// import Akkreditation from '../components/akkreditation'
import Participants from '../components/participants'
// import TimeOptions from "../components/block/timeOptions.js"
// import Email from "../components/block/email.js"
import AllSpeakers from "../components/speakers/speakersBlock"
import Footer from '../components/layout/footer'
// import { Hidden } from "@material-ui/core"
import "../components/layout.css"



const useStyles = makeStyles(theme => ({
  mainDiv: {
    overflowX: 'hidden',
    margin: `0 auto`,
    padding: '0% 7%',
    '@media (max-width: 1024px)': {
      padding: '0% 7%',
    },
    '@media (max-width:767px)': {
      padding: '0% 5%',
    },
  },
  mainTitle:{
    marginTop: '5%',
    fontWeight: 800,
    maxWidth: '70%',
    color: "#010D2C",
    fontSize: "5.9vw",
    lineHeight: '1.2em',
    '@media  (max-width: 1024px)': {
      marginTop: '10vh',
      fontSize: "9vw",
      maxWidth: '100%',
    },
    '@media (max-width:767px)': {
      marginTop: '12vh',
      fontSize: "7vh",
      maxWidth: '100%',
    },
  },
  subtitle:{
    fontSize: '1.3vw',
    marginTop: '3%',
    maxWidth: '42%',
    color: "#010D2C",
    '@media (max-width: 1024px)': {
      marginTop: '30px',
      fontSize: "2.5vw",
      maxWidth: '70%',
    },
    '@media (max-width:767px)': {
      marginTop: '30px',
      fontSize: '2vh',
      maxWidth: '100%',
    },
  },
  footer: {
    marginTop: '10%',
    paddingBottom: '5%',
    "@media(max-width: 1024px)": {
      marginTop: '15%',
      paddingBottom: '10%',
    },
    "@media(max-width: 767px)": {
      marginTop: '30%',
      paddingBottom: '10%',
    },
  },
}))

export default function Page() {

  const classes = useStyles();

  const IsMobileDevice = typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches;

  // start of query

  const data = useStaticQuery(graphql`
    query main {
      prismicMainpage {
        lang
        dataRaw
        data {
          body {
            ... on PrismicMainpageBodyPartner {
              id
              primary {
                partner_logo {
                  localFile {
                    childImageSharp {
                      fluid {
                        src
                        srcWebp
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
          about {
            html
          }
          about_organisator {
            html
          }
          img_loc_1 {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcWebp
                }
              }
            }
            alt
          }
          img_loc_2 {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcWebp
                }
              }
            }
            alt
          }
          img_loc_3 {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcWebp
                }
              }
            }
            alt
          }
          logo_found {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcWebp
                }
              }
            }
            alt
          }
          logo_roskongress {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcWebp
                }
              }
            }
            alt
          }
          content_url {
            url
          }
          ros_url {
            url
          }
        }
      }
      allPrismicProgram {
        edges {
          node {
            uid
          }
        }
      }
      allPrismicSpeaker {
        edges {
          node {
            uid
          }
        }
      }
    }
  `
  )

  // end of query

  const newDf = data.prismicMainpage.dataRaw
  const htmlData = data.prismicMainpage.data

  const ifProgram = data.allPrismicProgram.edges.length
  const ifPartners = data.prismicMainpage.data.body.length
  const ifSpeakers = data.allPrismicSpeaker.edges.length

  return(
    <div>

      <SEO
        title={'Устойчивое будущее'}
        keywords={''}
        description={'Авторитетное пространство диалога между государством, бизнесом и экспертным сообществом в направлении устойчивого развития'}
      />

      <div className={classes.mainDiv}>

        <Header
          IsMobileDevice={IsMobileDevice}
          ifProgram={ifProgram}
          ifPartners={ifPartners}
          ifSpeakers={ifSpeakers}
          menuPoint1={newDf.menu_point_1[0].text}
          menuPoint2={newDf.menu_point_2[0].text}
          menuPoint3={newDf.menu_point_3[0].text}
          menuPoint4={newDf.menu_point_4[0].text}
          />

        <Typography variant='h1' className={classes.mainTitle}>
          {newDf.title[0].text}
        </Typography>

        <Typography variant='body1' className={classes.subtitle}>
          {newDf.sub_title[0].text}
        </Typography>

        <Place google_maps={newDf.google_maps.url}>
          {newDf.place[0].text}
        </Place>

        {/* <TimeOptions
          name={newDf.name[0].text}
          name_pre={newDf.name_pre[0].text}
          time={newDf.time[0].text}
          time_pre={newDf.time_pre[0].text}
          /> */}

        <Time >
          {newDf.date[0].text}
        </Time>

        {/* <Button>
          {newDf.button_ticket[0].text}
        </Button> */}

        <div style={{position: 'relative',}}>
          <BackgroundStart />
        </div>
        <BackgroundStartMobile />

        <Numbers
        speakerNumber={newDf.speaker_number}
        speakerTitle={newDf.speaker_title[0].text}
        programsNumber={newDf.programs_number}
        programsTitle={newDf.programs_title[0].text}
        partnersNumber={newDf.partners_number}
        partnersTitle={newDf.partners_title[0].text}
         />

        {ifProgram !== 0 ?
          <div id='program'>
            <Programmes
                title_program={newDf.title_program[0].text} />
          </div>
        :
          null
        }

        {ifProgram > 2 ?
          <Background2 />
        :
          null
        }

        {ifSpeakers !== 0 ?
          <div id='speakers'>
            <AllSpeakers/>
          </div>
        :
          null
        }

        <div id='participants'>
          <Participants
            title_participants={newDf.title_participants[0].text}
            participant1={newDf.participant1[0].text}
            participant2={newDf.participant2[0].text}
            participant3={newDf.participant3[0].text}
            participant4={newDf.participant4[0].text}
            participant5={newDf.participant5[0].text} />
        </div>

        {ifPartners !== 0 ?
          <div id='partners'>
            <Partners title_partners={newDf.title_partners[0].text} content={htmlData.body} />
          </div>
        :
          null
        }

        <div id='about'>
          <About
            textInter={<div dangerouslySetInnerHTML={{ __html: htmlData.about.html }} />}
            textFound={<div dangerouslySetInnerHTML={{ __html: htmlData.about_organisator.html }} />}
            logoFound_srcWebp={htmlData.logo_found.localFile.childImageSharp.fluid.srcWebp}
            logoFound_src={htmlData.logo_found.localFile.childImageSharp.fluid.src}
            logoFound_alt={htmlData.logo_found.alt}
            logoRos_srcWebp={htmlData.logo_roskongress.localFile.childImageSharp.fluid.srcWebp}
            logoRos_src={htmlData.logo_roskongress.localFile.childImageSharp.fluid.src}
            logoRos_alt={htmlData.logo_roskongress.alt}
            content_url= {htmlData.content_url.url}
            ros_url ={htmlData.ros_url.url}
            />
        </div>

        <BackgroundLocation />
        <BackgroundStartMobile2 />

        <PlaceLocation google_maps={newDf.google_maps.url}>
          {newDf.place[0].text}
        </PlaceLocation>

        <PlacePhoto
          img_loc_1src={htmlData.img_loc_1.localFile.childImageSharp.fluid.src}
          img_loc_1srcWebp={htmlData.img_loc_1.localFile.childImageSharp.fluid.srcWebp}
          img_loc_1_alt= {htmlData.img_loc_1.alt} 
          img_loc_2src={htmlData.img_loc_2.localFile.childImageSharp.fluid.src}
          img_loc_2srcWebp={htmlData.img_loc_2.localFile.childImageSharp.fluid.srcWebp}
          img_loc_2_alt= {htmlData.img_loc_2.alt}
          img_loc_3src={htmlData.img_loc_3.localFile.childImageSharp.fluid.src}
          img_loc_3srcWebp={htmlData.img_loc_3.localFile.childImageSharp.fluid.srcWebp}
          img_loc_3_alt= {htmlData.img_loc_3.alt}                
          />

        {/* <div id='contacts'>
          <Email
            email={newDf.email[0].text}
            email_title={newDf.email_title[0].text}
            />
        </div> */}
        <div id='contacts' className={classes.footer}>
          <Footer
            ifProgram={ifProgram}
            ifPartners={ifPartners}
            ifSpeakers={ifSpeakers}
            menuPoint1={newDf.menu_point_1[0].text}
            menuPoint2={newDf.menu_point_2[0].text}
            menuPoint3={newDf.menu_point_3[0].text}
            menuPoint4={newDf.menu_point_4[0].text}
            time={newDf.date[0].text}
            place={newDf.place[0].text}
            google_maps={newDf.google_maps.url}
            email={newDf.email[0].text}
            phoneNumber={newDf.phone_number[0].text}
            phoneNumberUrl={newDf.phone_number_url[0].text}
            />
        </div>
        </div>



        {/*<Akkreditation
          akkreditation={newDf.title_akred[0].text}
          button={newDf.button_text[0].text}
          />*/}

        {/* <Typography style={{marginTop: '10%',}} /> */}

    </div>
  )
}
