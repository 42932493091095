import React from 'react'
import Programm from '../programm'
import Heading from './heading'

export default function Programmes({ title_program }) {
  return (
    <div>
      <Heading title_program={title_program} />
      <Programm />
    </div>
  )
}
