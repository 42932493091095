import React from 'react'
import Background from '../../static/background/bg2.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  bg: {
    position: 'relative',
    width: '100vw',
    marginLeft: '-7vw',
    marginTop: '-18%',
    zIndex: '-10',
    '@media (max-width:767px)': {
      transform: 'scale(2)',
      marginTop: '-25%',
    },
  },
}))

export default function BackgroundStart() {

  const classes = useStyles();

  return (
    <div style={{width: '90vw', height: '20vw',}}>
      <div className={classes.bg}>
        <Background />
      </div>
    </div>
  )
}
