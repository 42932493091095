import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '../layout/menu'
import MenuMobile from '../layout/menuMobile'
import Logo from '../../static/logo.svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 25,
    paddingBottom: 25,
  },
  rootMobile: {
    paddingTop: 25,
    paddingBottom: 25,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '266px',
    '@media (max-width: 1024px)': {
      width: '160px',
    },
    '@media (max-width:767px)': {
      width: '266px',
    },
  },
  logoMobile: {
    width: '60%',
    transform: 'translateX(calc(50vw - 5vw - (30vw * 0.9) - 33px))'
  },
}))

export default function Header({ menuPoint1, menuPoint2, menuPoint3, menuPoint4, IsMobileDevice, ifProgram, ifPartners, ifSpeakers }) {
  const classes = useStyles();
  return (
    <div>
      {IsMobileDevice ?
        <div className={classes.rootMobile}>
          <MenuMobile
            ifPartners={ifPartners}
            ifProgram={ifProgram}
            ifSpeakers={ifSpeakers}
            menuPoint1={menuPoint1}
            menuPoint2={menuPoint2}
            menuPoint3={menuPoint3}
            menuPoint4={menuPoint4}
            />

          <div className={classes.logoMobile}>
            <Logo />
          </div>
        </div>
      :
        <div className={classes.root}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.menu}>
            <Menu
              ifPartners={ifPartners}
              ifProgram={ifProgram}
              ifSpeakers={ifSpeakers}
              menuPoint1={menuPoint1}
              menuPoint2={menuPoint2}
              menuPoint3={menuPoint3}
              menuPoint4={menuPoint4}
              />
          </div>
        </div>
      }
    </div>
  )
}
